<div class="row">
  <div class="col-md-12">
    <div class="card card-secondary commissions-box">
      <div class="card-header">
        <h4 class="card-title w-100">
          <span>Commission Statement Search</span>
        </h4>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group">
              <label>Statement Type</label>
              <select id="policyType" class="form-control" [(ngModel)]="policyType">
                <option value="0">All Statements</option>
                <option value="1">Daily</option>
                <option value="2">Monthly</option>
              </select>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label>Agents</label>
              <select id="includedAgents" class="form-control" [(ngModel)]="includedAgents">
                <option value="1">My Agent ID's</option>
                <option value="2" *ngIf="profile.status == 'Full'">My Downline</option>
                <option value="3" *ngIf="profile.status == 'Full'">Everyone</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Time Frame</label>
              <select id="timeFrame" class="form-control" [disabled]="!timeFrameEnable" [(ngModel)]="timeFrame" (change)="changeTimeFrame($event)">
                <option value="ALL">All Dates</option>
                <option value="MTD">Current Month</option>
                <option value="QTD">Current Quarter</option>
                <option value="WTD">Current Week</option>
                <option value="YTD">Current Year</option>
                <option value="MTDP">Prior Month</option>
                <option value="QTDP">Prior Quarter</option>
                <option value="WTDP">Prior Week</option>
                <option value="YTDP">Prior Year</option>
              </select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>From</label>
              <input type="text" readonly class="form-control" id="startDate" value="{{startDate | date:'MM/dd/yyyy'}}">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>To</label>
              <input type="text" readonly class="form-control" id="endDate" value="{{endDate | date:'MM/dd/yyyy'}}">
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="text-center">
          <input type="button" class="btn btn-secondary input-block-level" value="Search" (click)="searchStatements()">
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <div class="card card-secondary commissions-box" *ngIf="showSearch">
      <div class="card-header">
        <h4 [ngSwitch]="(statements$ | async)?.length" class="card-title w-100">
          <span *ngSwitchCase="1">Search Results (1 Statement - Double click to download)</span>
          <span *ngSwitchCase="0">Search Results (0 Statements)</span>
          <span *ngSwitchDefault>Search Results ({{(statements$ | async)?.length}} Statements - Double click to download)</span>
        </h4>
      </div>
      <div class="card-body">
        <div *ngIf="statements$ | async; else loading;">
          <ag-grid-angular style="width: 100%; height: 300px;"
                           class="ag-theme-alpine"
                           [rowData]="statements$ | async"
                           [columnDefs]="columnDefs"
                           [defaultColDef]="defaultColumnDef"
                           rowSelection='single'
                           [suppressRowClickSelection]="false"
                           (gridReady)="onGridReady($event)"
                           [overlayNoRowsTemplate]="noRowsTemplate"
                           (rowDoubleClicked)="onRowDoubleClicked($event)">
          </ag-grid-angular>
        </div>
        <ng-template #loading>
          <div class="row justify-content-center">
            <component-loading></component-loading>
          </div>          
        </ng-template>
      </div>
    </div>
  </div>
</div>
