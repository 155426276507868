import { Component, OnInit, AfterViewInit } from '@angular/core';
import { RowSelectedEvent } from 'ag-grid';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Profile } from '../../../models/profile';
import { trainingcontent } from '../../../models/trainingcontent';
import { trainingdisplay } from '../../../models/trainingdisplay';
import { trainingdisplayitem } from '../../../models/trainingdisplayitem';
import { CloudStorageService } from '../../../services/cloud-storage';
import { SiteParameterService } from '../../../services/siteparameter.service';
import * as moment from 'moment';
import { take } from 'rxjs/operators';
@Component({
  selector: 'app-training',
  templateUrl: './training.component.html',
  styles: [
  ]
})
export class TrainingComponent implements OnInit {
  public profile!: Profile;
  private channelUpdate: Subscription;
  private gridApi1: any;
  private gridColumnApi1: any;
  private gridApi2: any;
  private gridColumnApi2: any;
  public training!: trainingdisplay;
  public showGroup: boolean = true;
  public showList: boolean = false;
  public showNew: boolean = false;
  public profileFullStatus = false;
  public overViewList: trainingcontent[] = [];
  public channelList: trainingcontent[] = [];
  public activeFilter: string = 'Category';
  public overviewGroupList: trainingdisplayitem[] = [];
  public channelGroupList: trainingdisplayitem[] = [];
  public overviewNewList: trainingdisplayitem[] = [];
  public channelNewList: trainingdisplayitem[] = [];
  columnDefs = [
    {
      field: 'icon', sortable: true, headerName: 'Type',
      cellRenderer: function (params: any) {
        return '<i class="' + params.value + '"</i>'
      }
    },
    { field: 'title', sortable: true, headerName: 'Name' },
    { field: 'description', sortable: true, headerName: 'Description' },
    {
      field: 'startDate', sortable: true, headerName: 'Date'      
    }
  ];
  constructor(private cloudStorageService: CloudStorageService, private siteParmService: SiteParameterService, private toastr: ToastrService) {
    this.channelUpdate = this.siteParmService.getChannelUpdate().subscribe
      (message => {
        this.getTraining();
      });
  }
  ngOnInit(): void {
    this.profile = this.siteParmService.getStaticProfile();
    this.getTraining();
    if (!this.profile.trainingVisited) {
      this.toastr.info('The navigation toggle here provides detailed information for the currently selected business channel. You can also sort and filter by using the dropdown to the right ', 'Information', {
        positionClass: 'toast-product-info', tapToDismiss: true, timeOut: 0
      });
      this.profile.trainingVisited = true;
      this.siteParmService.setProfile(this.profile);
    }
    this.cloudStorageService.updatePageVisited(this.profile.auth0Id, 3)
      .pipe(take(1))
      .subscribe((resp: boolean) => { });
    this.profileFullStatus = this.profile.status == 'Full';
  }
  ngAfterViewInit(): void {
    $('#menu li a').click(function () {
      if (this.parentElement?.className.indexOf('dropdown') == -1) {
        $('#menu').find("*").removeClass("router-link-active");
        $(this).addClass('router-link-active');
      }
    });
    if (this.profile.agents.length > 0 && this.profileFullStatus) {
      if (!this.profile.trainingOverviewSelected) {
        $('#tchannel-tab').addClass('router-link-active');
        $('#tChannel').addClass('tab-pane active');
      } else {
        $('#toverview-tab').addClass('router-link-active');
        $('#tOverview').addClass('tab-pane active');
      }
    } else {
      $('#toverview-tab').addClass('router-link-active');
      $('#tOverview').addClass('tab-pane active');
    }
  }
  getTraining(): void {
    var selectedBusinessChannel = this.profile ? this.profile.channelPreferenceValue : 0;
    this.cloudStorageService.getTraining(selectedBusinessChannel)
      .pipe(take(1))
      .subscribe(training => {
        this.training = training;
        this.overViewList = this.training.overviewList;
        this.channelList = this.training.channelList;
        if (this.activeFilter == 'Category') {
          this.overviewGroupList = this.training.overviewByCategory;
          this.channelGroupList = this.training.channelByCategory;
        } else {
          this.overviewGroupList = this.training.overviewByType;
          this.channelGroupList = this.training.channelByType;
        }
        this.channelNewList = this.training.channelNew;
        this.overviewNewList = this.training.overviewNew;
      });
  }
  toggleView(name: string) {
    this.activeFilter = name;
    switch (name) {
      case 'Category':
        this.showGroup = true;
        this.showList = false;
        this.showNew = false;
        this.overviewGroupList = this.training.overviewByCategory;
        this.channelGroupList = this.training.channelByCategory;
        break;
      case 'Type':
        this.showGroup = true;
        this.showList = false;
        this.showNew = false;
        this.overviewGroupList = this.training.overviewByType;
        this.channelGroupList = this.training.channelByType;
        break;
      case 'Name':
        this.showGroup = false;
        this.showList = true;
        this.showNew = false;
        break;
      case 'Latest':
        this.showGroup = false;
        this.showList = false;
        this.showNew = true;
        break;
    }
  }
  onGrid1Ready(params: any) {
    this.gridApi1 = params.api;
    this.gridColumnApi1 = params.columnApi;
    this.gridApi1.sizeColumnsToFit();
  }
  onGrid2Ready(params: any) {
    this.gridApi2 = params.api;
    this.gridColumnApi2 = params.columnApi;
    this.gridApi2.sizeColumnsToFit();
  }
  getItem(url: string): void {
    window.open(url, '_blank');
  }
  onRowClicked(event: RowSelectedEvent) {
    window.open(event.data.linkUrl, '_blank');
  }
  updatePreference(overview: boolean) {    
    this.profile.trainingOverviewSelected = overview;
    this.siteParmService.setProfile(this.profile);
    this.cloudStorageService.updatePreference("Training", this.profile.auth0Id, overview)
      .pipe(take(1))
      .subscribe((resp: boolean) => { });
    this.gridApi1.sizeColumnsToFit();
    this.gridApi2.sizeColumnsToFit();
  }
  ngOnDestroy() { // It's a good practice to unsubscribe to ensure no memory leaks
    this.channelUpdate.unsubscribe();
  }
}
