<div class="col-md-12" *ngIf="profileFullStatus">
  <div class="accordion print-suppress" id="leftaccordion">
    <ng-container *ngFor="let alert of alertItems; let i = index">
      <div class="row">
        <div class="card card-{{alert.alertType}} w-100">
          <div class="card-header" id="heading{{i}}">
            <h4 class="card-title" (click)="toggleAccordion(i)">
              <a class="d-block w-100 collapsed" data-toggle="collapse" href="#collapse{{i}}" aria-expanded="false">
                <span><i *ngIf="alert.alertType ==='warning' || alert.alertType ==='danger'" class="fas fa-exclamation-triangle"></i><i *ngIf="alert.alertType ==='primary'" class="fas fa-info-circle"></i></span>&nbsp;&nbsp;{{alert.title}}&nbsp;&nbsp;<span><i class="{{getAccordionArrowStyle(i)}}"></i></span>
              </a>
            </h4>
          </div>
          <div *ngIf="i === 0" id="collapse{{i}}" class="collapse" data-parent="#leftaccordion">
            <div class="card-body">
              <p innerHTML={{alertItems[i].description}}></p>
            </div>
          </div>
          <div *ngIf="i !== 0" id="collapse{{i}}" class="collapse" data-parent="#leftaccordion">
            <div class="card-body">
              <p innerHTML={{alertItems[i].description}}></p>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
