// src/app/app-routing.module.ts

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginPageComponent } from './presentation/pages/login/login.component';
import { AnnuityLoginPageComponent } from './presentation/pages/annuitylogin/annuitylogin.component';
import { AnnuityBankRepLoginPageComponent } from './presentation/pages/annuitybankreplogin/annuitybankreplogin.component';
import { HomeComponent } from './presentation/pages/home/home.component';
import { FormsPageComponent } from './presentation/pages/forms/forms.component';
import { ProfileComponent } from './presentation/pages/profile/profile.component';
import { TermsPageComponent } from './presentation/pages/terms/terms.component';
import { CompleteProfileComponent } from './presentation/pages/completeprofile/completeprofile.component';
import { ProductsComponent } from './presentation/pages/products/products.component';
import { BriefcaseComponent } from './presentation/pages/briefcase/briefcase.component';
import { TrainingComponent } from './presentation/pages/training/training.component';
import { EAppComponent } from './presentation/pages/eapp/eapp.component';
import { AuthGuard } from '@auth0/auth0-angular';
import { UserListPageComponent } from './presentation/pages/userlist/userslist.component';
import { AnonymousGuard } from './authentication/anonymous.guard';
import { ProfileGuard } from './authentication/profile.guard';
import { ContactPageComponent } from './presentation/pages/contact/contact.component';
import { AdminPageComponent } from './presentation/pages/admin/admin.component';
import { DocumentsPageComponent } from './presentation/pages/documents/documents.component';
import { RedirectingComponent } from './presentation/pages/redirecting/redirecting.component';
import { CollectionsPageComponent } from './presentation/pages/collections/collections.component';
import { RatesPageComponent } from './presentation/pages/rates/rates.component';

const routes: Routes = [
  {
    path: '',
    component: LoginPageComponent,
    pathMatch: 'full',    
    canActivate: [AnonymousGuard],
  },
  {
    path: 'annuitylogin',
    component: AnnuityLoginPageComponent,
    pathMatch: 'full',
  },
  {
    path: 'annuitybankreplogin',
    component: AnnuityBankRepLoginPageComponent,
    pathMatch: 'full',
  },
  {
    path: 'home',
    component: HomeComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'completeprofile',
    component: CompleteProfileComponent,
    canActivate: [AuthGuard, ProfileGuard],
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard, ProfileGuard],
  },
  {
    path: 'products',
    component: ProductsComponent,
    canActivate: [AuthGuard, ProfileGuard],
  },
  {
    path: 'forms',
    component: FormsPageComponent,
    canActivate: [AuthGuard, ProfileGuard],
  },
  {
    path: 'briefcase',
    component: BriefcaseComponent,
    canActivate: [AuthGuard, ProfileGuard],
  },
  {
    path: 'training',
    component: TrainingComponent,
    canActivate: [AuthGuard, ProfileGuard],
  },
  {
    path: 'eapp',
    component: EAppComponent,
    canActivate: [AuthGuard, ProfileGuard],
  },
  {
    path: 'terms',
    component: TermsPageComponent
  },
  {
    path: 'userlist',
    component: UserListPageComponent,
    canActivate: [AuthGuard, ProfileGuard]
  },
  {
    path: 'admin',
    component: AdminPageComponent,
    canActivate: [AuthGuard, ProfileGuard]
  },
  {
    path: 'contact',
    component: ContactPageComponent
  },
  {
    path: 'documents',
    component: DocumentsPageComponent
  },
  {
    path: 'redirecting',
    component: RedirectingComponent,
  },
  {
    path: 'collections',
    component: CollectionsPageComponent,
    canActivate: [AuthGuard, ProfileGuard]
  },
  {
    path: 'rates',
    component: RatesPageComponent,
    canActivate: [AuthGuard, ProfileGuard],
  },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
