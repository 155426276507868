import { Component, OnInit } from '@angular/core';
import { SiteParameterService } from '../../../services/siteparameter.service'
import { Profile } from '../../../models/profile'
import { Subscription } from 'rxjs';
import { CloudStorageService } from '../../../services/cloud-storage';
import { alertItem } from '../../../models/alertItem';

@Component({
  selector: 'app-channelnews',
  templateUrl: './channelnews.component.html',
  styleUrls: ['./channelnews.component.css']
})
export class ChannelNewsComponent implements OnInit {
  alertItems: alertItem[] = [];
  profile: Profile;
  accordionExanded: boolean[] = [];
  public profileFullStatus = false;
  private subscriptionName: Subscription; //important to create a subscription
  constructor(public cloudStorageService: CloudStorageService, private siteParmService: SiteParameterService) {
    this.profile = this.siteParmService.getStaticProfile();
    this.subscriptionName = this.siteParmService.getChannelUpdate().subscribe
      (message => {
        this.cloudStorageService.getAlertsByChannel(this.profile.channelPreferenceValue)
          .subscribe(alerts => this.alertItems = alerts);
      });
    this.profileFullStatus = this.profile.status == 'Full';
  }
  ngOnInit(): void {
    this.cloudStorageService.getAlertsByChannel(this.profile.channelPreferenceValue)
      .subscribe(alerts =>
        this.alertItems = alerts
      );
  }
  toggleAccordion(index: number) {
    this.accordionExanded[index] = !this.accordionExanded[index];
  }
  getAccordionArrowStyle(index: number) {
    return this.accordionExanded[index] ? 'fas fa-angle-up' : 'fas fa-angle-down';
  }
  ngOnDestroy() { // It's a good practice to unsubscribe to ensure no memory leaks
    this.subscriptionName.unsubscribe();
  }
}
