<div class="nav-container mb-1">
  <nav class="navbar navbar-expand-md navbar-light bg-light">
    <div class="container-fluid justify-content-center">
      <ul class="nav navbar-nav" id="menu">
        <li class="nav-item">
          <a class="nav-link router-link-exact-active" id="RouteList-tab" data-toggle="tab" href="#RouteList" (click)="onClickTabSelect('Route List')">Active Policies</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="PendingCollections-tab" data-toggle="tab" href="#PendingCollections" (click)="onClickTabSelect('Pending Collections')">Pending Collections</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="BatchHistory-tab" data-toggle="tab" href="#BatchHistory" (click)="onClickTabSelect('Batch History')">Batch History</a>
        </li>
      </ul>
    </div>
  </nav>
</div>

<br />
<ng-container *ngIf="showCollectionParameters">
  <div class="form-group row">
    <div class="col-lg-2 col-xl-3">
      <label class="col-form-label text-right">Selected Agent</label>
      <select id="selectedAgent" class="form-control" [(ngModel)]="selectedAgentId" (change)="changeAgentId($event)">
        <option disabled></option>
        <option *ngFor="let agent of agents" value="{{agent.agentId}}">
          {{agent.agentName}}
        </option>
      </select>
    </div>
    <div class="col-lg-1 col-xl-2">
      <div *ngIf="enableRouteListSearch">
        <label class="col-form-label text-right">Search</label>
        <div>
          <input type="text" id="search" class="form-control" [ngModel]="searchTerm" (input)="onSearchChanged()" />
        </div>
      </div>
    </div>
    <div class="col-lg-9 col-xl-7">
      <ng-container *ngIf="showBillFormListCheckboxes">
        <label class="col-form-label text-right">Billing Method</label>
        <div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="displayAgentCollect" [ngModel]="agentCollectCheckValue" (change)="checkBillingMethod($event)">
            <label class="form-check-label mr-1" for="displayAgentCollect">Agent Collect ({{agentCollectCount}})</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="displayAutoPremiumLoan" [ngModel]="autoPremiumLoanCheckValue" (change)="checkBillingMethod($event)">
            <label class="form-check-label mr-1" for="displayAutoPremiumLoan">Auto Premium Loan ({{autoPremiumLoanCount}})</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="displayBankDraft" [ngModel]="bankDraftCheckValue" (change)="checkBillingMethod($event)">
            <label class="form-check-label mr-1" for="displayBankDraft">Bank Draft ({{bankDraftCount}})</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="displayCreditCard" [ngModel]="creditCardCheckValue" (change)="checkBillingMethod($event)">
            <label class="form-check-label mr-1" for="displayCreditCard">Credit Card ({{creditCardCount}})</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="displayDirectBill" [ngModel]="directBillCheckValue" (change)="checkBillingMethod($event)">
            <label class="form-check-label mr-1" for="displayDirectBill">Direct Bill ({{directBillCount}})</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="displayGroupListBill" [ngModel]="groupListBillCheckValue" (change)="checkBillingMethod($event)">
            <label class="form-check-label" for="displayGroupListBill">Group/List Bill ({{groupListBillCount}})</label>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

</ng-container>

<ng-container *ngIf="showSubmitBatchButton">
  <div class="col-md-12">
    <div class="row justify-content-center">
      <div class="form-group">
        <input type="button" class="btn btn-secondary input-block-level mr-3" name="submitBatchButton" id="submitBatchButton"
               value="Submit Collections" (click)="submitBatch()" [disabled]="disableSubmitBatchButton">
        <input type="button" class="btn btn-secondary input-block-level" name="submitBatchButton" id="submitBatchButton"
               value="Collect for Lapsed Policy" (click)="openLapsedCollectionForm()" [disabled]="selectedAgentId === ''">
      </div>
    </div>
  </div>
</ng-container>

<!-- Tab panes -->
<div class="tab-content clearfix" id="tabPanes">
  <div class="tab-pane active" id="RouteList">
    <div class="row justify-content-center">
      <div class="col-md-12">
        <ng-container *ngIf="showRouteList">
          <div class="row admin-box">
            <div class="col-md-12">
              <div class="row justify-content-center" *ngIf="!(routeList$ | async)">
                <component-loading></component-loading>
              </div>
              <div *ngIf="(routeList$ | async)" style="overflow-x:scroll;">
                <ag-grid-angular id="routeList" style="width: 100%; height: 640px;"
                                 class="ag-theme-alpine"
                                 [rowData]="routeList$ | async"
                                 [columnDefs]="columnRoutleListDefs"
                                 [defaultColDef]="defaultColumnDef"
                                 [autoGroupColumnDef]="autoGroupColumnDef"
                                 [groupDefaultExpanded]="groupDefaultExpanded"
                                 rowSelection='single'
                                 [rowDeselection]="true"
                                 [suppressRowClickSelection]="false"
                                 (rowSelected)="onRouteListRowClicked($event)"
                                 (gridReady)="onGridReady1($event)"
                                 (rowDataChanged)="onRowDataRouteListUpdated($event)"
                                 [allowContextMenuWithControlKey]="true"
                                 [getContextMenuItems]="getRouteListContextMenuItems"
                                 [suppressContextMenu]="false"
                                 [overlayNoRowsTemplate]="noRowsTemplate"
                                 [enableBrowserTooltips]="true"
                                 (bodyScroll)="onBodyScroll($event)"
                                 [gridOptions]="gridOptions">
                </ag-grid-angular>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="tab-pane" id="PendingCollections">
    <div class="row justify-content-center">
      <div class="col-md-12">
        <ng-container *ngIf="showPendingCollectionsList">
          <div class="row admin-box justify-content-center">
            <div class="col-md-12">
              <ag-grid-angular id="pendingCollectionsList" style="width: 100%; height: 640px;"
                               class="ag-theme-alpine"
                               [rowData]="pendingCollectionsRowData"
                               [columnDefs]="columnPendingCollectionsDefs"
                               [defaultColDef]="defaultColumnDef"
                               [headerHeight]="pendingCollectionsListHeaderHeight"
                               [overlayNoRowsTemplate]="noRowsTemplate"
                               (rowDataChanged)="onRowDataCollectionsListUpdated($event)"
                               [allowContextMenuWithControlKey]="true"
                               [getContextMenuItems]="getPendingCollectionsListContextMenuItems"
                               [suppressContextMenu]="false"
                               (gridReady)="onGridReady2($event)"
                               [enableBrowserTooltips]="true"
                               [gridOptions]="gridOptions2">
              </ag-grid-angular>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="tab-pane" id="BatchHistory">
    <div class="row justify-content-center">
      <div class="col-md-12">
        <div class="row admin-box justify-content-center">
          <div class="col-md-12">
            <ag-grid-angular id="batchHistoryList" style="width: 100%; height: 640px;"
                             class="ag-theme-alpine"
                             [rowData]="batchHistoryRowData"
                             [columnDefs]="columnBatchHistoryDefs"
                             [defaultColDef]="defaultColumnDef"
                             [overlayNoRowsTemplate]="noRowsTemplate"
                             rowSelection='single'
                             [rowDeselection]="true"
                             [suppressRowClickSelection]="false"
                             (rowSelected)="onBatchHistoryListRowClicked($event)"
                             [allowContextMenuWithControlKey]="true"
                             [getContextMenuItems]="getBatchHistoryListContextMenuItems"
                             [suppressContextMenu]="false"
                             (gridReady)="onGridReady3($event)"
                             [gridOptions]="gridOptions3">
            </ag-grid-angular>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="showCollectionForm">
    <div class="card card-primary" id="CollectionForm">
      <div class="card-header">
        <div class="row">
          <div class="col-md-10">
            <h4 class="card-title w-100">
              <span>Collect Policy - {{policyNumber ? sequenceNo + " - " + policyNumber : "Loading..."}}</span>
            </h4>
          </div>
          <div class="col-md-2 my-auto text-right">
            <i class="fas fa-2x fa-times-circle" style="cursor:pointer" (click)="closeCollectionForm()"></i>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="showLapseAlert" class="alert alert-danger text-center" role="alert">{{ lapseAlertMessage }}</div>
    <div *ngIf="showNoPremiumCollected" class="alert alert-danger text-center" role="alert">No Premium Collected for this Policy</div>
    <div *ngIf="showSuspenseAlert" class="alert alert-danger text-center" role="alert">
      The Available Suspense for this policy is not enough to cover the amount specified to lower the net collection.
    </div>
    <div *ngIf="showLoanBalanceAlert" class="alert alert-danger text-center" role="alert">
      The repayment amount is greater than the amount owed for the current loan amount.
    </div>
    <form #addCollectionForm="ngForm" (ngSubmit)="addCollection(addCollectionForm.value)">
      <div class="card-body">
        <div class="col-md-12">
          <div class="row justify-content-center">
            <p class="card-text mb-2">
              Modal Premium
              <b>{{ modalPremium | currency }}</b> // Total Premium Due  <b>{{ premiumDue | currency }}</b> // Paid To <b>{{ paidToDate }}</b>
            </p>
          </div>
          <div class="row justify-content-center">
            <p class="card-text">The current commission amount for this policy is <b>{{ currentCommRate | percent }}</b></p>
          </div>
        </div>

        <div *ngIf="availableSuspenseAmount > 0 || suspenseAmount > 0">
          <div class="col-md-12 mt-3">
            <div class="row justify-content-center">
              <p class="card-text mb-2 text-success"><b>>> This policy currently has {{ availableSuspenseAmount | currency }} held in available suspense <<</b></p>
            </div>
          </div>
        </div>

        <div *ngIf="currentLoanBalance > 0 || loanPaymentAmount > 0">
          <div class="col-md-12 mt-3">
            <div class="row justify-content-center">
              <p class="card-text mb-2 text-success"><b>>> This policy has a current loan balance of {{ currentLoanBalance | currency }} <<</b></p>
            </div>
          </div>
        </div>

        <div class="col-md-12 mt-5">
          <div class="row justify-content-center vertical-center">
            <label class="form-label float-right mr-3">Amount Collected:</label>
            <div>
              <div class="input-group mb-3">
                <span class="input-group-text">$</span>
                <input type="text" class="form-control" name="amountCollected" id="amountCollected" value="{{ amountCollected }}" (input)="onAmountCollectedChanged($event)" (keypress)="decimalFilter($event)">
                <span class="btn btn-secondary input-block-level ml-2" id="autoApplyButton" (click)="autoApply()" ngbTooltip="Auto Apply" tooltipClass="lbig-tooltip" placement="right">
                  <i class="fad fa-wand-magic" style="--fa-primary-color: #FFD43B; --fa-secondary-color: #FFFFFA; --fa-secondary-opacity: 0.8;"></i>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="row justify-content-center my-3">
          <div class="col-md-8">

            <div class="container">
              <fieldset class="border rounded-3 p-3">
                <legend class="float-none w-auto px-3 text-sm">Allocation (must be fully allocated to Save)</legend>

                <div class="row mb-4">
                  <div class="col-md-6 justify-content-left vertical-center">
                    <label class="form-label ml-3">Months To Be Paid:</label>
                  </div>
                  <div class="col-md-3 vertical-center">
                    <div class="input-group ml-3">
                      <button class="btn btn-secondary" type="button" (click)="decreaseMonthsPaid()">-</button>
                      <input type="text" class="form-control text-center" name="monthsPaid" id="monthsPaid" value="{{ monthsPaid }}" disabled>
                      <button class="btn btn-secondary" type="button" (click)="increaseMonthsPaid()">+</button>
                    </div>
                  </div>
                  <div class="col-md-3 vertical-center">
                    <div class="input-group">
                      <span class="input-group-text">$</span>
                      <input type="text" class="form-control" name="monthlyPremiumPaid" id="monthlyPremiumPaid" value="{{ monthlyPremiumPaid | number : '1.2-2' }}" disabled>
                    </div>
                  </div>
                </div>

                <div *ngIf="availableSuspenseAmount > 0 || suspenseAmount > 0">
                  <div class="row mb-4">
                    <div class="col-md-6 justify-content-left vertical-center">
                      <label class="form-label ml-3">-  Apply From Suspense:</label>
                    </div>
                    <div class="col-md-3 justify-content-center vertical-center">
                      <div class="custom-control custom-switch">
                        <input type="checkbox" class="custom-control-input" id="useSuspense" (change)="useSuspenseChecked($event)" [checked]="isUseSuspenseChecked">
                        <label class="custom-control-label" for="useSuspense"></label>
                      </div>
                    </div>
                    <div class="col-md-3 vertical-center">
                      <div class="input-group">
                        <span class="input-group-text">$</span>
                        <input type="text" class="form-control" name="suspenseAmt" id="suspenseAmt" value="{{ suspenseAmount }}" (input)="onSuspenseAmtChanged($event)" (keypress)="decimalFilter($event)" [disabled]="!isUseSuspenseChecked">
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="col-md-6 justify-content-left vertical-center">
                    <label class="form-label ml-3">+  Overpayment To Suspense:</label>
                  </div>
                  <div class="col-md-3 justify-content-center vertical-center">
                    <div class="custom-control custom-switch">
                      <input type="checkbox" class="custom-control-input" id="makeOverPayment" (change)="makeOverPaymentChecked($event)" [checked]="isMakeOverPaymentChecked">
                      <label class="custom-control-label" for="makeOverPayment"></label>
                    </div>
                  </div>
                  <div class="col-md-3 vertical-center">
                    <div class="input-group">
                      <span class="input-group-text">$</span>
                      <input type="text" class="form-control" name="overpaymentAmt" id="overpaymentAmt" value="{{ overpaymentAmount }}" (input)="onOverpaymentAmtChanged($event)" (keypress)="decimalFilter($event)" [disabled]="!isMakeOverPaymentChecked">
                    </div>
                  </div>
                </div>

                <div *ngIf="currentLoanBalance > 0 || loanPaymentAmount > 0">
                  <div class="row mb-4">
                    <div class="col-md-6 justify-content-left vertical-center">
                      <label class="form-label ml-3">+  Loan Repayment:</label>
                    </div>
                    <div class="col-md-3 justify-content-center vertical-center">
                      <div class="custom-control custom-switch">
                        <input type="checkbox" class="custom-control-input" id="makeLoanPayment" (change)="makeLoanPaymentChecked($event)" [checked]="isMakeLoanPaymentChecked">
                        <label class="custom-control-label" for="makeLoanPayment"></label>
                      </div>
                    </div>
                    <div class="col-md-3 vertical-center">
                      <div class="input-group">
                        <span class="input-group-text">$</span>
                        <input type="text" class="form-control" name="loanpaymentAmt" id="loanpaymentAmt" value="{{ loanPaymentAmount }}" (input)="onLoanPaymentAmtChanged($event)" (keypress)="decimalFilter($event)" [disabled]="!isMakeLoanPaymentChecked">
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row mb-4 pl-3 pr-3 justify-content-center">
                  <div class="col-md-12 vertical-center " style="border-bottom: 1px solid lightgrey">
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="col-md-9 justify-content-left vertical-center">
                    <label class="form-label ml-3">Allocated Collection Amount:</label>
                  </div>
                  <div class="col-md-3 vertical-center">
                    <div class="input-group">
                      <span class="input-group-text">$</span>
                      <input type="text" class="form-control" name="premiumCollected" id="premiumCollected" value="{{ premiumCollected | number : '1.2-2' }}" disabled>
                    </div>
                  </div>
                </div>

              </fieldset>
            </div>
          </div>

        </div>
        <div class="card-footer">
          <div class="text-center">
            <input type="submit" class="btn btn-secondary input-block-level mr-3" name="saveButton" id="saveButton" value="Save" [disabled]="disableSaveCollectionButton">
            <input *ngIf="collectionId" type="button" class="btn btn-danger input-block-level mr-3" name="deleteButton" id="deleteButton" value="Delete" (click)="deleteCollection(collectionId)">
            <input type="button" class="btn btn-secondary input-block-level" name="cancelButton" id="cancelButton" value="Cancel" (click)="closeCollectionForm()">
          </div>
        </div>
      </div>
    </form>
  </ng-container>

  <ng-container *ngIf="showLapsedCollectionForm">
    <div class="card card-primary" id="CollectionForm">
      <div class="card-header">
        <div class="row">
          <div class="col-md-10">
            <h4 class="card-title w-100">
              <span>Collect Lapsed Policy - {{policyNumber ? sequenceNo + " - " + policyNumber : "Loading..."}}</span>
            </h4>
          </div>
          <div class="col-md-2 my-auto text-right">
            <i class="fas fa-2x fa-times-circle" style="cursor:pointer" (click)="closeLapsedCollectionForm()"></i>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="showLapseAlert" class="alert alert-danger text-center" role="alert">Months Paid must equal or exceed the Months Due</div>
    <div *ngIf="showNoPremiumCollected" class="alert alert-danger text-center" role="alert">No Premium Collected for this Policy</div>
    <div *ngIf="showCollectedError" class="alert alert-danger text-center" role="alert">
      An error has occurred during the Save of your collection.  Please contact Home Service Agent Support for guidance.
    </div>
    <form #addLapsedCollectionForm="ngForm" (ngSubmit)="addCollection(addLapsedCollectionForm.value)">
      <div class="card-body">


        <div class="row mb-4 justify-content-center">
          <div class="col-md-4">
            <div class="form-group">
              <label>Policy Number</label>
              <input type="text" class="form-control" name="policyNumber" id="policyNumber" [(ngModel)]="policyNumber" [disabled]="!enablePolicyNumberSearch">
              <input *ngIf="enablePolicyNumberSearch" type="button" class="btn btn-secondary mt-2" value="Search for Policy"
                     (click)="searchPolicyNumber(addLapsedCollectionForm.controls.policyNumber)">
              <input *ngIf="!enablePolicyNumberSearch" type="button" class="btn btn-secondary mt-2" value="Search Again" (click)="resetPolicyNumberSearch()">
              &nbsp;&nbsp;&nbsp;
              <span class="align-bottom" *ngIf="showPolicyFound">
                <i class="fad fa-check-circle fa-2x" style="--fa-primary-color: green; --fa-secondary-color: green; "></i>&nbsp;
                Lapsed Policy Found!
              </span>
              <span class="align-bottom" *ngIf="showPolicyNotFound">
                <i class="fad fa-times-circle fa-2x" style="--fa-primary-color: darkred; --fa-secondary-color: darkred; "></i>&nbsp;
                Lapsed Policy Not Found!
              </span>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label>Insured Name</label>
              <input type="text" class="form-control" name="insuredName" id="insuredName" readonly value="{{ insuredName }}">
            </div>
          </div>
        </div>

        <ng-container *ngIf="showLapseCollectionInfo">

          <div class="row mb-4 justify-content-center">
            <div class="col-md-7 vertical-center " style="border-bottom: 1px solid lightgrey">
            </div>
          </div>
          <div class="col-md-12">
            <div class="row justify-content-center">
              <p class="card-text mb-2">
                Modal Premium
                <b>{{ modalPremium | currency }}</b> // Total Premium Due  <b>{{ premiumDue | currency }}</b> // Paid To <b>{{ paidToDate }}</b>
              </p>
            </div>
            <div class="row justify-content-center">
              <p class="card-text">The current commission amount for this policy is <b>{{ currentCommRate | percent }}</b></p>
            </div>
          </div>

          <div class="col-md-12 mt-5">
            <div class="row justify-content-center vertical-center">
              <label class="form-label float-right mr-3">Amount Collected:</label>
              <div>
                <div class="input-group mb-3">
                  <span class="input-group-text">$</span>
                  <input type="text" class="form-control" name="amountCollected" id="amountCollected" value="{{ amountCollected }}" (input)="onAmountCollectedChanged($event)" (keypress)="decimalFilter($event)">
                </div>
              </div>
            </div>
          </div>

          <div class="row justify-content-center my-3">
            <div class="col-md-8">

              <div class="container">
                <fieldset class="border rounded-3 p-3">
                  <legend class="float-none w-auto px-3 text-sm">Allocation (must be fully allocated to Save)</legend>

                  <div class="row mb-4">
                    <div class="col-md-6 justify-content-left vertical-center">
                      <label class="form-label ml-3">Months To Be Paid:</label>
                    </div>
                    <div class="col-md-3 vertical-center">
                      <div class="input-group ml-3">
                        <button class="btn btn-secondary" type="button" (click)="decreaseMonthsPaid()">-</button>
                        <input type="text" class="form-control text-center" name="monthsPaid" id="monthsPaid" value="{{ monthsPaid }}" disabled>
                        <button class="btn btn-secondary" type="button" (click)="increaseMonthsPaid()">+</button>
                      </div>
                    </div>
                    <div class="col-md-3 vertical-center">
                      <div class="input-group">
                        <span class="input-group-text">$</span>
                        <input type="text" class="form-control" name="monthlyPremiumPaid" id="monthlyPremiumPaid" value="{{ monthlyPremiumPaid | number : '1.2-2' }}" disabled>
                      </div>
                    </div>
                  </div>

                  <div class="row mb-4">
                    <div class="col-md-6 justify-content-left vertical-center">
                      <label class="form-label ml-3">+  Overpayment To Suspense:</label>
                    </div>
                    <div class="col-md-3 justify-content-center vertical-center">
                      <div class="custom-control custom-switch">
                        <input type="checkbox" class="custom-control-input" id="makeOverPayment" (change)="makeOverPaymentChecked($event)" [checked]="isMakeOverPaymentChecked">
                        <label class="custom-control-label" for="makeOverPayment"></label>
                      </div>
                    </div>
                    <div class="col-md-3 vertical-center">
                      <div class="input-group">
                        <span class="input-group-text">$</span>
                        <input type="text" class="form-control" name="overpaymentAmt" id="overpaymentAmt" value="{{ overpaymentAmount }}" (input)="onOverpaymentAmtChanged($event)" (keypress)="decimalFilter($event)" [disabled]="!isMakeOverPaymentChecked">
                      </div>
                    </div>
                  </div>

                  <div class="row mb-4 pl-3 pr-3 justify-content-center">
                    <div class="col-md-12 vertical-center " style="border-bottom: 1px solid lightgrey">
                    </div>
                  </div>

                  <div class="row mb-4">
                    <div class="col-md-9 justify-content-left vertical-center">
                      <label class="form-label ml-3">Allocated Collection Amount:</label>
                    </div>
                    <div class="col-md-3 vertical-center">
                      <div class="input-group">
                        <span class="input-group-text">$</span>
                        <input type="text" class="form-control" name="premiumCollected" id="premiumCollected" value="{{ premiumCollected | number : '1.2-2' }}" disabled>
                      </div>
                    </div>
                  </div>

                </fieldset>
              </div>
            </div>

          </div>
          <div class="card-footer">
            <div class="text-center">
              <input type="submit" class="btn btn-secondary input-block-level mr-3" name="saveLapsedButton" id="saveLapsedButton" value="Save" [disabled]="disableSaveLapsedCollectionButton">
              <input *ngIf="collectionId" type="button" class="btn btn-danger input-block-level mr-3" name="deleteLapsedButton" id="deleteLapsedButton" value="Delete" (click)="deleteCollection(collectionId)">
              <input type="button" class="btn btn-secondary input-block-level" name="cancelLapsedButton" id="cancelLapsedButton" value="Cancel" (click)="closeLapsedCollectionForm()">
            </div>
          </div>

        </ng-container>

      </div>
    </form>
  </ng-container>

  <ng-container *ngIf="showGroupCollectionForm">
    <div class="card card-primary" id="groupCollectionForm">
      <div class="card-header">
        <div class="row">
          <div class="col-md-10">
            <h4 class="card-title w-100">
              <span>Collect All Policies - {{ sequenceNo }}</span>
            </h4>
          </div>
          <div class="col-md-2 my-auto text-right">
            <i class="fas fa-2x fa-times-circle" style="cursor:pointer" (click)="closeGroupCollectionForm()"></i>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="showLapseAlert" class="alert alert-danger text-center" role="alert">Months Paid must equal or exceed the Months Due</div>
    <div *ngIf="showNoGroupPremiumCollected" class="alert alert-danger text-center" role="alert">No Premium Collected for this Policy</div>
    <form #addGroupCollectionForm="ngForm" (ngSubmit)="addGroupCollection(addGroupCollectionForm.value)">
      <div class="card-body">
        <div class="col-md-12">
          <div class="row justify-content-center">
            <p class="card-text mb-2">Modal Premium  <b>{{ groupModalPremium | currency }}</b> // Total Premium Due  <b>{{ groupPremiumDue | currency }}</b></p>
          </div>
          <div class="row justify-content-center">
            <p class="card-text">The current commission amount for this policy is <b>{{ currentGroupCommRate | percent }}</b></p>
          </div>
        </div>

        <div class="row justify-content-center my-3">
          <div class="col-md-4">
            <div class="card card-secondary">
              <div class="card-body">
                <div class="row justify-content-center">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Months To Be Paid</label>
                      <div class="input-group w-100 mb-3">
                        <button class="btn btn-secondary" type="button" (click)="decreaseGroupMonthsPaid()">-</button>
                        <input type="text" class="form-control text-center" name="monthsGroupPaid" id="monthsGroupPaid" size="10" value="{{ monthsGroupPaid }}" disabled>
                        <button class="btn btn-secondary" type="button" (click)="increaseGroupMonthsPaid()">+</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12">
          <div class="row justify-content-center">
            <div class="form-group">
              <label>Net Collection</label>
              <input type="text" class="form-control" name="premiumCollected" id="premiumCollected" readonly value="{{ premiumGroupCollected | currency }}">
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="text-center">
          <input type="submit" class="btn btn-secondary input-block-level mr-3" name="saveGroupButton" id="saveGroupButton" value="Save">
          <input type="button" class="btn btn-secondary input-block-level" name="cancelGroupButton" id="cancelGroupButton" value="Cancel" (click)="closeGroupCollectionForm()">
        </div>
      </div>
    </form>
  </ng-container>

  <ng-container *ngIf="showSequenceNumberForm">
    <div class="card card-primary" id="SequenceNumberForm">
      <div class="card-header">
        <div class="row">
          <div class="col-md-10">
            <h4 class="card-title w-100">
              <span>Edit Sequence Number</span>
            </h4>
          </div>
          <div class="col-md-2 my-auto text-right">
            <i class="fas fa-2x fa-times-circle" style="cursor:pointer" (click)="closeSequenceNumberForm()"></i>
          </div>
        </div>
      </div>
    </div>
    <form #updateSequenceNumberForm="ngForm" (ngSubmit)="updateSequenceNumber(updateSequenceNumberForm.value)">
      <div class="card-body">
        <div class="row justify-content-center">
          <div class="col-md-3">
            <div class="form-group">
              <label>Sequence #</label>
              <input type="text" class="form-control" name="sequenceNo" id="sequenceNo" [(ngModel)]="sequenceNo">
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="text-center">
          <input type="submit" class="btn btn-secondary input-block-level mr-3" name="saveButton" id="saveButton" value="Save">
          <input type="button" class="btn btn-secondary input-block-level" name="cancelButton" id="cancelButton" value="Cancel" (click)="closeSequenceNumberForm()">
        </div>
      </div>
    </form>
  </ng-container>

  <ng-container *ngIf="showSequenceContactForm">
    <div class="card card-primary" id="SequenceContactForm">
      <div class="card-header">
        <div class="row">
          <div class="col-md-10">
            <h4 class="card-title w-100">
              <span>Edit Sequence Payor {{contactName ? "" : "- Loading..."}}</span>
            </h4>
          </div>
          <div class="col-md-2 my-auto text-right">
            <i class="fas fa-2x fa-times-circle" style="cursor:pointer" (click)="closeSequenceContactForm()"></i>
          </div>
        </div>
      </div>
    </div>
    <form #updateSequenceContactForm="ngForm" (ngSubmit)="updateSequenceContact(updateSequenceContactForm.value)">
      <div class="card-body">
        <div class="row justify-content-center">
          <div class="col-md-6">
            <div class="form-group">
              <label>Sequence #</label>
              <input type="text" class="form-control" name="sequenceNo" id="sequenceNo" [(ngModel)]="sequenceNo">
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-md-6">
            <div class="form-group">
              <label>Name</label>
              <input type="text" class="form-control" name="contactName" id="contactName" [(ngModel)]="contactName">
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-md-6">
            <div class="form-group">
              <label>Address</label>
              <input type="text" class="form-control mb-1" name="contactAddressLine1" id="contactAddressLine1" [(ngModel)]="contactAddressLine1">
              <input type="text" class="form-control" name="contactAddressLine2" id="contactAddressLine2" [(ngModel)]="contactAddressLine2">
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-md-2">
            <div class="form-group">
              <label>City</label>
              <input type="text" class="form-control" name="contactCity" id="contactCity" [(ngModel)]="contactCity">
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label>State</label>
              <input type="text" class="form-control" name="contactState" id="contactState" [(ngModel)]="contactState">
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label>Zip</label>
              <input type="text" class="form-control" name="contactZip" id="contactZip" [(ngModel)]="contactZip">
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-md-6">
            <div class="form-group">
              <label>Email</label>
              <input type="text" class="form-control" name="contactEmail" id="contactEmailAddress" [(ngModel)]="contactEmailAddress">
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-md-6">
            <div class="form-group">
              <label>Phone</label>
              <input type="text" class="form-control" name="contactPhone" id="contactPhone" [(ngModel)]="contactPhone">
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-md-6">
            <div class="form-group">
              <label>Notes</label>
              <textarea class="form-control" rows="3" name="contactNotes" id="contactNotes" [(ngModel)]="contactNotes"></textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="text-center">
          <input type="submit" class="btn btn-secondary input-block-level mr-3" name="saveButton" id="saveButton" value="Save">
          <input type="button" class="btn btn-secondary input-block-level" name="cancelButton" id="cancelButton" value="Cancel" (click)="closeSequenceContactForm()">
        </div>
      </div>
    </form>
  </ng-container>

  <!-- BEGINNING OF POLICY DETAIL-->
  <div class="card card-primary search-box" *ngIf="showPolicyDetail">
    <div class="card-header">
      <div class="row">
        <div class="col-md-10">
          <h4 class="card-title w-100">
            <span class="print-header">
              Policy Detail -
              {{selectedPolicy.policyNumber ? selectedPolicy.policyNumber + " - " + selectedPolicy.insuredName + "&nbsp;&nbsp;&nbsp;[ values as of " + selectedPolicy.asOfDate + " ]": "Loading..."}}
            </span>
          </h4>
        </div>
        <div class="col-md-2 my-auto text-right print-suppress">
          <i class="fas fa-2x fa-thin fa-print" (click)="printPolicyDetail()" style="cursor:pointer" ngbTooltip="Print Policy Info" tooltipClass="lbig-tooltip"></i>&nbsp;&nbsp;&nbsp;&nbsp;
          <i class="fas fa-2x fa-times-circle" (click)="closePolicyDetail()" style="cursor:pointer" ngbTooltip="Close this Policy" tooltipClass="lbig-tooltip"></i>
        </div>
      </div>
    </div>
    <div class="nav-container mb-3">
      <nav class="navbar navbar-expand-md navbar-light bg-light">
        <div class="container-fluid justify-content-center">
          <ul class="nav navbar-nav" id="policyDetailMenu">
            <li class="nav-item">
              <a class="nav-link router-link-exact-active" id="PolicyDetails-tab" data-toggle="tab" href="#PolicyDetails" (click)="policyDetailTabView('#PolicyDetails-tab')">Policy Info</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="Coverages-tab" data-toggle="tab" href="#Coverages" (click)="policyDetailTabView('#Coverages-tab')">Coverage Info</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="PremiumHistory-tab" data-toggle="tab" href="#PremiumHistory" (click)="policyDetailTabView('#PremiumHistory-tab')">Payment History</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="StatusHistory-tab" data-toggle="tab" href="#StatusHistory" (click)="policyDetailTabView('#StatusHistory-tab')">Status History</a>
            </li>
            <li class="nav-item" *ngIf="displayList('Underwriting')">
              <a class="nav-link" id="Underwriting-tab" data-toggle="tab" href="#UnderwritingStatus" (click)="policyDetailTabView('#Underwriting-tab')">App Processing</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="Hierarchy-tab" data-toggle="tab" href="#Hierarchy" (click)="policyDetailTabView('#Hierarchy-tab')">Hierarchy</a>
            </li>
            <li class="nav-item" *ngIf="displayList('BeneficiaryInfo')">
              <a class="nav-link" id="Beneficiary-tab" data-toggle="tab" href="#Beneficiary" (click)="policyDetailTabView('#Beneficiary-tab')">Beneficiary Info</a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
    <div class="tab-content clearfix" id="tabDetailPanes">
      <!--BEGIN DETAILS-->
      <div class="tab-pane active" id="PolicyDetails">
        <div class="card-body">
          <div *ngIf="selectedPolicy.policyNumber === '**ERROR**'" class="alert alert-danger text-center" role="alert">Error Loading Policy Information</div>
          <div class="row">
            <div class="col-md-8">
              <div class="form-group">
                <label>Product Plan</label>
                <input type="text" class="form-control" readonly value='{{selectedPolicy.productPlan}}'>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>Estimated Death Benefit</label>
                <input type="text" class="form-control" readonly value='{{selectedPolicy.deathBenefit}}'>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label>Policy Number</label>
                <input type="text" class="form-control" readonly value='{{selectedPolicy.policyNumber}}'>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>Agent</label>
                <input type="text" class="form-control" readonly value='{{selectedPolicy.agentID}}'>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>Agent Name</label>
                <input type="text" class="form-control" readonly value='{{selectedPolicy.agentName}}'>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label>Submit Date</label>
                <input type="text" class="form-control" readonly value='{{selectedPolicy.submitDate}}'>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>Issue Date</label>
                <input type="text" class="form-control" readonly value='{{selectedPolicy.issueDate}}'>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>Paid To Date</label>
                <input type="text" class="form-control" readonly value='{{selectedPolicy.paidToDate}}'>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label>Billing Mode</label>
                <input type="text" class="form-control" readonly value='{{selectedPolicy.billingMode}}'>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>Face Amount</label>
                <input type="text" class="form-control" readonly value='{{selectedPolicy.faceAmount}}'>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>Premium</label>
                <input type="text" class="form-control" readonly value='{{selectedPolicy.premium}}'>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="accordion" id="clientInfoAccordion">

                <div class="card card-secondary">

                  <div class="card-header" id="clientInfoHeading">

                    <h4 class="card-title w-100" (click)="toggleClientInfoAccordion()">
                      <a class="d-block w-100 collapsed" data-toggle="collapse" href="#clientInfoCollapse" aria-expanded="false">
                        Client Details&nbsp;&nbsp;<span><i class="{{getClientInfoAccordionArrowStyle()}}"></i></span>
                      </a>
                    </h4>
                  </div>

                  <div id="clientInfoCollapse" class="collapse" aria-labelledby="clientInfoHeading" data-parent="#clientInfoAccordion">

                    <div class="card-body">
                      <div class="row">

                        <div class="col-md-4" *ngIf="selectedPolicy.insuredName">
                          <div class="card card-secondary">
                            <div class="card-body">
                              <div class="row center">
                                <div class="col-md-12">
                                  <div class="form-group">
                                    <strong>Insured</strong>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-12 ml-3">
                                  <div class="info-box-text">{{selectedPolicy.insuredName}}</div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-12 ml-3">
                                  <div class="info-box-text">{{selectedPolicy.insuredAddress}}</div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-12 ml-3">
                                  <div class="info-box-text">Phone:&nbsp;{{ selectedPolicy.insuredPhone | phone }}</div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-12 ml-3">
                                  <div class="info-box-text">DOB:&nbsp;{{selectedPolicy.insuredDob}}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-4" *ngIf="selectedPolicy.ownerName">
                          <div class="card card-secondary">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-md-12">
                                  <div class="form-group">
                                    <strong>Owner</strong>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-12 ml-3">
                                  <div class="info-box-text">{{selectedPolicy.ownerName}}</div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-12 ml-3">
                                  <div class="info-box-text">{{selectedPolicy.ownerAddress}}</div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-12 ml-3">
                                  <div class="info-box-text">Phone:&nbsp;{{ selectedPolicy.ownerPhone | phone }}</div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-12 ml-3">
                                  <div class="info-box-text">DOB:&nbsp;{{selectedPolicy.ownerDob}}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-4" *ngIf="selectedPolicy.payorName">
                          <div class="card card-secondary">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-md-12">
                                  <div class="form-group">
                                    <strong>Payor</strong>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-12 ml-3">
                                  <div class="info-box-text">{{selectedPolicy.payorName}}</div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-12 ml-3">
                                  <div class="info-box-text">{{selectedPolicy.payorAddress}}</div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-12 ml-3">
                                  <div class="info-box-text">Phone:&nbsp;{{ selectedPolicy.payorPhone | phone }}</div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-12 ml-3">
                                  <div class="info-box-text">DOB:&nbsp;{{selectedPolicy.payorDob}}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--END DETAILS-->
      <div class="tab-pane" id="Coverages">
        <div class="row justify-content-center">
          <div class="col-10">
            <ag-grid-angular style="height: 300px;"
                             class="ag-theme-alpine"
                             [rowData]="coverageRowData"
                             [columnDefs]="coverageColumnDefs"
                             [defaultColDef]="defaultColumnDef"
                             rowSelection='none'
                             (gridReady)="onGridReady4($event)"
                             [suppressRowClickSelection]="true"
                             [overlayNoRowsTemplate]="noRowsTemplate"
                             [ag-grid-resize]>
            </ag-grid-angular>
          </div>
        </div>
      </div>
      <div class="tab-pane justify-content-center" id="PremiumHistory">
        <div class="row justify-content-center">
          <div class="col-8">
            <ag-grid-angular style="height: 300px;"
                             class="ag-theme-alpine"
                             [rowData]="paymentRowData"
                             [columnDefs]="paymentColumnDefs"
                             [defaultColDef]="defaultColumnDef"
                             rowSelection='none'
                             (gridReady)="onGridReady5($event)"
                             [suppressRowClickSelection]="true"
                             [overlayNoRowsTemplate]="noRowsTemplate"
                             [ag-grid-resize]>
            </ag-grid-angular>
          </div>
        </div>
      </div>
      <div class="tab-pane justify-content-center" id="StatusHistory">
        <div class="row justify-content-center">
          <div class="col-4">
            <ag-grid-angular style="height: 300px;"
                             class="ag-theme-alpine"
                             [rowData]="statusHistoryRowData"
                             [columnDefs]="statusHistoryColumnDefs"
                             [defaultColDef]="defaultColumnDef"
                             rowSelection='none'
                             (gridReady)="onGridReady6($event)"
                             [suppressRowClickSelection]="true"
                             [overlayNoRowsTemplate]="noRowsTemplate"
                             [ag-grid-resize]>
            </ag-grid-angular>
          </div>
        </div>
      </div>
      <div class="tab-pane justify-content-center" id="UnderwritingStatus" *ngIf="displayList('Underwriting')">
        <div class="row justify-content-center">
          <div class="col-8">
            <ag-grid-angular style="height: 300px;"
                             class="ag-theme-alpine"
                             [rowData]="underwritingStatusRowData"
                             [columnDefs]="underwritingStatusColumnDefs"
                             [defaultColDef]="defaultColumnDef"
                             rowSelection='none'
                             (gridReady)="onGridReady7($event)"
                             [suppressRowClickSelection]="true"
                             [overlayNoRowsTemplate]="noRowsTemplate"
                             [ag-grid-resize]>
            </ag-grid-angular>
          </div>
        </div>
      </div>
      <div class="tab-pane justify-content-center" id="Hierarchy">
        <div class="row justify-content-center">
          <div class="col-8">
            <ag-grid-angular style="height: 300px;"
                             class="ag-theme-alpine"
                             [rowData]="hierarchyRowData"
                             [columnDefs]="hierarchyColumnDefs"
                             [defaultColDef]="defaultColumnDef"
                             rowSelection='none'
                             (gridReady)="onGridReady8($event)"
                             [suppressRowClickSelection]="true"
                             [overlayNoRowsTemplate]="noRowsTemplate"
                             [ag-grid-resize]>
            </ag-grid-angular>
          </div>
        </div>
      </div>
      <div class="tab-pane justify-content-center" id="Beneficiary" *ngIf="displayList('BeneficiaryInfo')">
        <div class="row justify-content-center">
          <div class="col-8">
            <div class="form-group">
              <label>Info</label>
              <input type="text" class="form-control" readonly value='{{beneficiaryInfoRowData[0].info}}'>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-8">
            <div class="form-group">
              <label>Irrevocable Assignment</label>
              <input type="text" class="form-control" readonly value='{{beneficiaryInfoRowData[0].irAssign | yesNo}}'>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-8">
            <ag-grid-angular style="height: 300px;"
                             class="ag-theme-alpine"
                             [rowData]="beneficiaryRowData"
                             [columnDefs]="beneficiaryColumnDefs"
                             [defaultColDef]="defaultColumnDef"
                             rowSelection='none'
                             (gridReady)="onGridReady9($event)"
                             [suppressRowClickSelection]="true"
                             [overlayNoRowsTemplate]="noRowsTemplate"
                             [ag-grid-resize]>
            </ag-grid-angular>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END OF POLICY DETAIL -->
</div>

