import { Injectable } from '@angular/core';
import { HttpClient  } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AgentGrid } from '../models/agentGrid';
import { profileOptions } from '../models/profileOptions';
import { UserGrid } from '../models/userGrid';
import { CarouselImage } from '../models/carouselimage';
import { AgentLinkResponse } from '../models/agentLinkResponse';
import { Profile } from '../models/profile';
import { alertItem } from '../models/alertItem';
import { documentGroup } from '../models/documentGroup';
import { channel } from '../models/channel';
import { channelProduct } from '../models/channelProduct';
import { product } from '../models/product';
import { feature } from '../models/feature';
import { trainingdisplay } from '../models/trainingdisplay';
import { searchParams } from '../models/searchParams';
import { EAppConfig } from '../models/eappconfig';
import { EApp } from '../models/eapp';
import { SiteSetting } from '../models/siteSetting';


const endpoint = './api/clouddata/';
@Injectable({
  providedIn: 'root'
})

export class CloudStorageService {
  private httpOptions = {};
  constructor(private http: HttpClient) { }
  createUserProfile(thedata: AgentLinkResponse): Observable<any> {
    return this.http.post<Profile>(endpoint + 'CreateUserProfile', thedata);
  }
  getUserProfile(id: string): Observable<any> {
    return this.http.get(endpoint + 'GetUserProfile?auth0ID=' + id);
  }
  getImpersonationProfile(id: string): Observable<any> {
    return this.http.get(endpoint + 'GetImpersonationProfile?userID=' + id);
  }
  getLinkedAgents(auth0Id: string): Observable<AgentGrid[]> {
    return this.http.get<AgentGrid[]>(endpoint + 'GetLinkedAgents?auth0ID=' + auth0Id);
  }
  updateLinkedAgents(thedata: AgentLinkResponse): Observable<any> {
    return this.http.post<Profile>(endpoint + 'updateLinkedAgents', thedata);
  }
  getProfileOptions(auth0Id: string): Observable<profileOptions> {
    return this.http.get<profileOptions>(endpoint + 'GetProfileOptions?auth0ID=' + auth0Id);
  }
  getUserList(): Observable<UserGrid[]> {
    return this.http.get<UserGrid[]>(endpoint + 'GetUserList');
  }
  updateProfile(authId: string, defaultAgentId: string, displayName: string): Observable<boolean> {
    return this.http.get<boolean>(endpoint + 'UpdateProfile?auth0ID=' + authId + '&defaultAgentId=' + defaultAgentId + '&displayName=' + displayName);
  }
  getUserRestriction(id: string, area: string): Observable<any> {
    return this.http.get(endpoint + 'GetUserRestriction?userID=' + id + '&area=' + area);
  }
  getAlertsByChannel(channelID: number): Observable<alertItem[]> {
    return this.http.get<alertItem[]>(endpoint + 'GetAlerts?luChannelID=' + channelID);
  }
  getCarouselImages(): Observable<CarouselImage[]> {
    return this.http.get<CarouselImage[]>(endpoint + 'GetCarouselImages');
  }
  getDocuments(channelID: number, limitedAccessOnly: boolean): Observable<documentGroup[]> {
    return this.http.get<documentGroup[]>(endpoint + 'getDocuments?luChannelID=' + channelID + '&limitedaccessonly=' + limitedAccessOnly);
  }
  updatePageVisited(auth0ID: string, pageTypeId: number): Observable<boolean> {    
    return this.http.get<boolean>(endpoint + 'updatePageVisited?auth0ID=' + auth0ID + "&pageTypeID=" + pageTypeId);
  }
  getChannel(channelID: number): Observable<channel> {
    return this.http.get<channel>(endpoint + 'GetChannelInfo?luChannelID=' + channelID);
  }
  getProductsByChannel(channelID: number): Observable<channelProduct> {
    return this.http.get<channelProduct>(endpoint + 'GetProducts?luChannelID=' + channelID);
  }
  getAnonymousProductsByChannel(channelID: number): Observable<channelProduct> {
    return this.http.get<channelProduct>(endpoint + 'GetAnonymousProducts?luChannelID=' + channelID);
  }
  getProductSectors(): Observable<channelProduct[]> {
    return this.http.get<channelProduct[]>(endpoint + 'GetProductSectors');
  }
  updatePreference(prefType: string, auth0ID: string, overview: boolean): Observable<boolean> {    
    return this.http.get<boolean>(endpoint + 'updatePreference?type=' + prefType +  '&auth0ID=' + auth0ID + '&overview=' + overview);
  }
  updateDateModified(agentLinkID: number, dateModified: string): Observable<boolean> {
    return this.http.get<boolean>(endpoint + 'UpdateDateModified?&agentLinkID=' + agentLinkID + '&dateModified=' + dateModified);
  }
  getTraining(channelID: number): Observable<trainingdisplay> {
    return this.http.get<trainingdisplay>(endpoint + 'getTrainingList?luChannelID=' + channelID);
  }
  getSearchParms(channelID: number): Observable<searchParams> {
    return this.http.get<searchParams>(endpoint + 'GetSearchParms?luChannelID=' + channelID);
  }
  searchForms(channelID: number, description: string, formClassID: string, formStateID: string, formTypeID: string, formProductID: string,
    formCompanyID: string, limitedAccessOnly: boolean): Observable<any> {    
    return this.http.get<any>(endpoint + 'searchForms?luChannelID=' + channelID + '&description=' + description + '&formClassID=' + formClassID + '&formTypeID=' +
      formTypeID + '&formProductID=' + formProductID + '&formStateID=' + formStateID + '&formCompanyID=' + formCompanyID + '&limitedaccessonly=' + limitedAccessOnly);
  }
  downloadCombinedForms(fileNames: string[], container: string) {
    return this.http.post<any>(endpoint + "DownloadCombinedForms", { fileNames: fileNames, formsContainerName: container}, { responseType: 'blob' as 'json' } );
  }
  emailForms(fileNames: string[], container: string, email: string, combined: boolean) {
    return this.http.post<any>(endpoint + "EmailForms", { fileNames: fileNames, formsContainerName: container, emailAddress: email, combineForms: combined });
  }
  getEAppURI(auth0ID: string, isProd: boolean): Observable<EApp> {
    return this.http.get<EApp>(endpoint + 'BuildEAppLoginURI?auth0ID=' + auth0ID + '&isProd=' + isProd);
  }
  checkeAppStatus(channelID: number): Observable<EApp> {
    return this.http.get<EApp>(endpoint + 'CheckeAppStatus?channelID=' + channelID);
  }
  getSiteSettings(): Observable<SiteSetting[]> {
    return this.http.get<SiteSetting[]>(endpoint + 'GetSiteSettings');
  }
  getEncryptedSaml(url: string, id: string, name: string, email: string): Observable<any> {
    return this.http.get(endpoint + 'GetEncryptedSaml?samlUrl=' + url + '&authID=' + id + '&name=' + name + '&email=' + email);
  }
}

