import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { AgentDownline } from '../../../models/downline';
import { Profile } from '../../../models/profile';
import { OnPremStorageService } from '../../../services/onprem-storage';
import { SiteParameterService } from '../../../services/siteparameter.service';
import { CellClassParams, RowSelectedEvent } from 'ag-grid-community';

@Component({
  selector: 'app-agent-hierarchy',
  templateUrl: './agent-hierarchy.component.html',
  styleUrls: ['./agent-hierarchy.component.css']
})
export class AgentHierarchyComponent implements OnInit {
  private selectedAgentID: string = "";
  profile!: Profile;
  public showSpinner = false;
  public showDownlineGrid = true;
  private agentUpdate: Subscription;
  public downlineAgents: AgentDownline[] = [];
  public filteredDownline: AgentDownline[] = [];
  public downlineAgentsByStatus: AgentDownline[] = [];
  public profileFullStatus = false;
  private gridApi: any;
  private gridColumnApi: any;
  private gridApi1: any;
  private gridColumnApi1: any;
  private hierarchy: string[] = [];
  private savedFilter: {} = {};
  public columnDefs = [
    { field: 'agentName', filter: 'agTextColumnFilter', filterParams: { suppressAndOrCondition: true } },
    { field: 'commissionLevel' },
    { field: 'status', filter: 'agTextColumnFilter', filterParams: { suppressAndOrCondition: true }, cellStyle: cellStyle }
  ];
  public columnDefs1 = [
    { headerName: 'Agent', valueGetter: idAndNameGetter, minWidth: 300, filterParams: { suppressAndOrCondition: true } },
    { field: 'agentName', filter: 'agTextColumnFilter', filterParams: { suppressAndOrCondition: true } },
    { field: 'commissionLevel' },
    { field: 'status', filter: 'agTextColumnFilter', filterParams: { suppressAndOrCondition: true }, cellStyle: cellStyle }
  ];
  public defaultColDef = { flex: 1 };
  public autoGroupColumnDef = {
    headerName: 'Agent',
    minWidth: 300,
    cellRendererParams: { suppressCount: true },
  };
  public groupDefaultExpanded = -1;
  public getDataPath = function (data: AgentDownline) {
    return data.orgHierarchy;
  };
  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
  onGridReady1(params: any) {
    this.gridApi1 = params.api;
    this.gridColumnApi1 = params.columnApi;
  }
  constructor(private onPremService: OnPremStorageService, private siteParmService: SiteParameterService) {
    this.agentUpdate = this.siteParmService.getAgentUpdate().subscribe
      (message => {
        this.selectedAgentID = message.value;
        this.getdownlineList();
      });
  }

  ngOnInit(): void {
    this.profile = this.siteParmService.getStaticProfile();
    this.selectedAgentID = this.profile.selectedAgentNumber;
    this.profileFullStatus = this.profile.status == 'Full';
    this.getdownlineList(); 
  }
  getdownlineList(): void {
    this.showSpinner = true;
    let limitedAccessOnly = !this.profileFullStatus;
    this.onPremService.getAgentDownlineList(this.selectedAgentID, limitedAccessOnly)
      .subscribe(downline => {
        this.downlineAgents = downline;
        this.downlineAgentsByStatus = [];
        for (let agent of this.downlineAgents) {
          if (agent.managerID) {
            this.hierarchy.length = 0;
            this.findManager(agent.managerID);
            this.hierarchy.reverse();
            this.hierarchy.push(agent.agentID + ' - ' + agent.agentName);
            agent.orgHierarchy = JSON.parse(JSON.stringify(this.hierarchy));
          } else {
            agent.orgHierarchy = [agent.agentID + ' - ' + agent.agentName];
          }
        }
        this.showSpinner = false;
      });
  }
  onRowSelected(event: any) {
    this.siteParmService.sendAgentDetailUpdate(event.data.agentID);
  }
  onFilterChanged(event: any) {
    const model = event.api.getFilterModel();
    if (JSON.stringify(this.savedFilter) !== JSON.stringify(model)) {
      this.savedFilter = model;
      if (!model.status && !model.agentName) {
        event.api.setRowData(this.downlineAgents);
        return;
      }
      if (model.status) {
        if (model.status.type == 'contains') {
          this.filteredDownline = JSON.parse(JSON.stringify(this.downlineAgents.filter(f => f.status.toUpperCase().indexOf(model.status.filter.toUpperCase()) != -1)));
        }
        if (model.status.type == 'startsWith') {
          this.filteredDownline = JSON.parse(JSON.stringify(this.downlineAgents.filter(f => f.status.toUpperCase().startsWith(model.status.filter.toUpperCase()))));
        }
      }
      if (model.agentName) {
        if (model.agentName.type == 'contains') {
          this.filteredDownline = JSON.parse(JSON.stringify(this.downlineAgents.filter(f => f.agentName.toUpperCase().indexOf(model.agentName.filter.toUpperCase()) != -1)));
        }
        if (model.agentName.type == 'startsWith') {
          this.filteredDownline = JSON.parse(JSON.stringify(this.downlineAgents.filter(f => f.agentName.toUpperCase().startsWith(model.agentName.filter.toUpperCase()))));
        }
      }
      if (model.status && model.agentName) {
        if (model.status.type == 'contains') {
          this.filteredDownline = JSON.parse(JSON.stringify(this.downlineAgents.filter(f => f.status.toUpperCase().indexOf(model.status.filter.toUpperCase()) != -1)));
        }
        if (model.status.type == 'startsWith') {
          this.filteredDownline = JSON.parse(JSON.stringify(this.downlineAgents.filter(f => f.status.toUpperCase().startsWith(model.status.filter.toUpperCase()))));
        }
        if (model.agentName.type == 'contains') {
          this.filteredDownline = JSON.parse(JSON.stringify(this.filteredDownline.filter(f => f.agentName.toUpperCase().indexOf(model.agentName.filter.toUpperCase()) != -1)));
        }
        if (model.agentName.type == 'startsWith') {
          this.filteredDownline = JSON.parse(JSON.stringify(this.filteredDownline.filter(f => f.agentName.toUpperCase().startsWith(model.agentName.filter.toUpperCase()))));
        }
      }
      for (let agent of this.filteredDownline) {
        agent.orgHierarchy.length = 0;
        if (agent.managerID) {
          this.hierarchy.length = 0;
          this.findFilteredManager(agent.managerID);
          this.hierarchy.reverse();
          this.hierarchy.push(agent.agentID + ' - ' + agent.agentName);
          agent.orgHierarchy = JSON.parse(JSON.stringify(this.hierarchy));
        } else {
          agent.orgHierarchy = [agent.agentID + ' - ' + agent.agentName];
        }
      }
      event.api.setRowData(this.filteredDownline);
      event.api.setFilterModel(model);
    }
  }
  onRowClicked(event: RowSelectedEvent) {
    this.siteParmService.sendAgentDetailUpdate(event.data.agentID);
  }
  findManager(s: string): any {
    let obj = this.downlineAgents.find(i => i.agentID === s);
    if (obj) {
      this.hierarchy.push(obj.agentID + ' - ' + obj.agentName);
      return this.findManager(obj.managerID);
    } else {
      return;
    }
  }
  findFilteredManager(s: string): any {
    let obj = this.filteredDownline.find(i => i.agentID === s);
    if (obj) {
      this.hierarchy.push(obj.agentID + ' - ' + obj.agentName);
      return this.findFilteredManager(obj.managerID);
    } else {
      return;
    }
  }
  onSearchChanged() {
    let field = document.getElementById('search') as HTMLInputElement;

    if (this.showDownlineGrid)
      this.gridApi.setQuickFilter(field.value);
    else
      this.gridApi1.setQuickFilter(field.value);
  }
  exportToExcel() {
    const params = {
      fileName: this.profile.selectedAgentNumber + '_AgentDownline',
      sheetName: 'Agent Downline'
    };

    if (this.showDownlineGrid)
      this.gridApi.exportDataAsExcel(params);
    else
      this.gridApi1.exportDataAsExcel(params);
  }
  onStatusClick($event: any) {
    let clickedElement = $event.target || $event.srcElement;

    if (clickedElement.nodeName === "BUTTON") {
      if (!clickedElement.classList.contains("active")) {
        let isCertainButtonAlreadyActive = clickedElement.parentElement.querySelector(".active");
        // if a Button already has Class: .active
        if (isCertainButtonAlreadyActive) {
          isCertainButtonAlreadyActive.classList.remove("active");
        }
        clickedElement.className += " active";        
      }

      selectedStatus = $event.srcElement.value;

      if (selectedStatus == "All") {
        this.showDownlineGrid = true;
      }
      else {
        this.showDownlineGrid = false;
        this.downlineAgentsByStatus = this.downlineAgents.filter(f => f.status == selectedStatus);
      }
      let field = document.getElementById('search') as HTMLInputElement;
      field.value = '';
    }
  }
  ngOnDestroy() { // It's a good practice to unsubscribe to ensure no memory leaks
    this.agentUpdate.unsubscribe();
  }
}
var selectedStatus = 'All';
function cellStyle(params: CellClassParams) {
  const color = buttonColorToColorCode(params.node.data.color.toLowerCase());
  return {
    color: color,
  };
}
function buttonColorToColorCode(color: any) {
  var fontColor = '#28a745';
  switch (color) {
    case 'success':
      fontColor = '#28a745';
      break;
    case 'warning':
      fontColor = '#ffc107';
      break;
    case 'info':
      fontColor = '#17a2b8';
      break;
    case 'danger':
      fontColor = '#dc3545';
      break;
  }
  return fontColor;
}
function idAndNameGetter(params: any) {
  return params.data.agentID + ' - ' + params.data.agentName;
}
