import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { EAppConfig } from '../../../models/eappconfig';
import { Profile } from '../../../models/profile';
import { CloudStorageService } from '../../../services/cloud-storage';
import { OnPremStorageService } from '../../../services/onprem-storage';
import { SiteParameterService } from '../../../services/siteparameter.service';
import { environment as env } from '../../../../environments/environment';
import { AbstractControl } from '@angular/forms';
import { SelectListItem } from '../../../models/selectlistitem';

@Component({
  selector: 'app-eapp',
  templateUrl: './eapp.component.html',
  styles: [
  ]
})
export class EAppComponent implements OnInit {
  public profile!: Profile;
  public eAppConfiguration!: EAppConfig;
  public eAppEnabled = false;
  public eAppMessage = '';
  public eAppURI = '';
  public isAnnuity = false;
  public isSuppHealth = false;
  public isWebHookConnection = false;
  public isRestricted = false;
  public restrictionMessage = '';
  public disableIllustrate = true;
  public product = '';
  public companyList: Array<SelectListItem> = Array<SelectListItem>();
  public company = '0';
  private subscriptionName: Subscription;
  @ViewChild("AnnuityEAppForm") eAppForm!: ElementRef;
  public annuityEAppURL = '';
  public samlResponse = '';
  // Generic Annuity Login with NO access to E App link
  private genericAnnuityLogin: string = 'annuity.advisor@lbig.com';

  constructor(private cloudStorageService: CloudStorageService, private onPremService: OnPremStorageService, private siteParmService: SiteParameterService) {
    this.subscriptionName = this.siteParmService.getChannelUpdate().subscribe
      (message => {
        this.profile = siteParmService.getStaticProfile();
        this.cloudStorageService.checkeAppStatus(this.profile.channelPreferenceValue).subscribe(eApp => {
          this.eAppEnabled = eApp.enabled && this.profile.emailAddress != this.genericAnnuityLogin;
          this.eAppMessage = eApp.message;
        });
        this.isWebHookConnection = this.profile.channelPreference === 'Supplemental Health [Plus Series]' ||
          this.profile.channelPreference === 'Home Service' || this.profile.channelPreference === 'Home Service [Career]' ? true : false;
        this.isSuppHealth = this.profile.channelPreference === 'Supplemental Health [Plus Series]';
        this.isAnnuity = this.profile.channelPreference === 'Annuity';
      });
  }
  ngOnInit(): void {
    this.profile = this.siteParmService.getStaticProfile();
    this.cloudStorageService.checkeAppStatus(this.profile.channelPreferenceValue).subscribe(eApp => {
      this.eAppEnabled = eApp.enabled && this.profile.emailAddress != this.genericAnnuityLogin;
      this.eAppMessage = eApp.message;
      this.isWebHookConnection = this.profile.channelPreference === 'Supplemental Health [Plus Series]' ||
        this.profile.channelPreference === 'Home Service' || this.profile.channelPreference === 'Home Service [Career]' ? true : false;
      this.isSuppHealth = this.profile.channelPreference === 'Supplemental Health [Plus Series]';
      this.isAnnuity = this.profile.channelPreference === 'Annuity';
    });
    this.cloudStorageService.getUserRestriction(this.profile.userId, 'EApp')
      .pipe(take(1))
      .subscribe(restriction => {
        this.restrictionMessage = restriction.message;
        this.isRestricted = this.restrictionMessage != '';
      });
    this.annuityEAppURL = env.dev.annuityEAppURL;
  }
  login(isProd: boolean): void {
    if (!this.isRestricted) {
      this.trackEvent(isProd ? 1 : 2);
      this.cloudStorageService.getEAppURI(this.profile.auth0Id, isProd)
        .pipe(take(1))
        .subscribe(eApp => {
          if (eApp.uri) {
            this.eAppURI = eApp.uri;
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.href = this.eAppURI;
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
          }
        });
    } else {
      alert(this.restrictionMessage)
    }
  }
  goToApp(isProd: boolean): void {
    if (!this.isRestricted) {
      if (this.profile.channelPreference === 'Supplemental Health [Plus Series]') {
        let tappURL = isProd ? env.dev.tappUrl : env.dev.tappUrl + "?trainingmode=1";
        window.open(tappURL, '_blank');
      } else {
        let mrsURL = isProd ? env.dev.mrsEAppUrl : env.dev.mrsEAppTrainingUrl;
        window.open(mrsURL, '_blank');
      }
      this.trackEvent(isProd ? 1 : 2);
    } else {
      alert(this.restrictionMessage)
    }
  }
  returnToApp(): void {
    if (!this.isRestricted) {
      let mrsURL = env.dev.mrsSuppHealthUrl;
      window.open(mrsURL, '_blank');
      this.trackEvent(3);
    } else {
      alert(this.restrictionMessage)
    }
  }
  viewCompletedApp(): void {
    if (!this.isRestricted) {
      let mrsURL = env.dev.mrsSuppHealthUrl;
      window.open(mrsURL, '_blank');
      this.trackEvent(4);
    } else {
      alert(this.restrictionMessage)
    }
  }
  trackEvent(eventId: number): void {
    this.onPremService.trackEvent(eventId, this.profile.auth0Id, '', this.profile.selectedAgentNumber, this.profile.channelPreferenceValue)
      .pipe(take(1))
      .subscribe();
  }
  openIllustration(): void {
    let companyName = '';

    switch (this.company) {
      case '1':
        companyName = 'LBL';
        break;

      case '2':
        companyName = 'CLIC';
        break;
    }

    this.onPremService.getAgentDetail(this.profile.selectedAgentNumber)
      .pipe(take(1))
      .subscribe(apiResponse => {
        let firstName = apiResponse.data.length > 0 ? apiResponse.data[0].firstName : '';
        let lastName = apiResponse.data.length > 0 ? apiResponse.data[0].lastName : '';
        const params = `c=${companyName}&FirstName=${firstName}&LastName=${lastName}`;
        var url = `https://myportal.lbig.com/illustrations-new/${this.product}?` + params;
        const a = document.createElement('a');
        a.setAttribute('style', 'display:none;');
        document.body.appendChild(a);
        a.href = url;
        a.target = '_blank';
        a.click();
        document.body.removeChild(a);
      });
  }
  onProductChange(formProductCtrl: AbstractControl) {
    this.product = formProductCtrl.value;
    this.company = '0';

    this.companyList.splice(0, this.companyList.length);

    switch (this.product) {
      case 'spiaIllustrations.php':
        this.companyList.push(({ value: 1, text: 'Liberty Bankers Life' }));
        this.companyList.push(({ value: 2, text: 'Capitol Life' }));
        break;

      case 'deferredIllustrations.php':
        this.companyList.push(({ value: 1, text: 'Liberty Bankers Life' }));
        this.companyList.push(({ value: 2, text: 'Capitol Life' }));
        break;

      case 'fiaIllustrations.php':
        this.companyList.push(({ value: 2, text: 'Capitol Life' }));
        break;

      default:
        this.companyList.splice(0, this.companyList.length);        
    }

    if (this.product != '' && this.company != '0') {
      this.disableIllustrate = false;
    }
    else {
      this.disableIllustrate = true;
    }
  }
  onCompanyChange(formCompanyCtrl: AbstractControl) {
    this.company = formCompanyCtrl.value;

    if (this.product != '' && this.company != '0') {
      this.disableIllustrate = false;
    }
    else {
      this.disableIllustrate = true;
    }
  }
  openAnnuityEApp(): void {
    this.cloudStorageService.getEncryptedSaml(this.annuityEAppURL, this.profile.auth0Id, this.profile.displayName, this.profile.emailAddress)
      .pipe(take(1))
      .subscribe(resp => {
        this.samlResponse = resp.content;
        setTimeout((_: any) => this.eAppForm.nativeElement.submit());
      });
  }
  ngOnDestroy() { // It's a good practice to unsubscribe to ensure no memory leaks
    this.subscriptionName.unsubscribe();
  }
}
