<div class="card card-secondary h-100">
  <div class="card-header">
    <h4 class="card-title w-100">
      <span>Downline Agents</span>
    </h4>
  </div>
  <div class="card-body">
    <div class="row" *ngIf="profileFullStatus">
      <div class="col-md-7">
        <div class="form-group row">
          <label class="col-lg-2 col-form-label text-center">Search</label>
          <div class="col-lg-10">
            <input type="text" id="search" class="form-control" (input)="onSearchChanged()" />
          </div>
        </div>
      </div>
      <div class="col-md-5">
        <div class="form-group export-button">
          <input type="button" class="btn btn-secondary" value="Export to Excel" (click)="exportToExcel()">
        </div>
      </div>
    </div>
    <div class="row" *ngIf="profileFullStatus">
      <div class="col-md-12">
        <div class="btn-group text align-content-stretch mb-2" (click)="onStatusClick($event)">

          <button class="btn btn-outline-primary mr-2 active" type="button" value="All">All</button>
          <button class="btn btn-outline-success mr-2" type="button" value="Active">Active</button>
          <button class="btn btn-outline-warning mr-2" type="button" value="Pending">Pending</button>
          <button class="btn btn-outline-info mr-2" type="button" value="Inactive">Inactive</button>
          <button class="btn btn-outline-danger" type="button" value="Terminated">Terminated</button>
        </div>
      </div>
    </div>
    <div *ngIf="showDownlineGrid">
      <div *ngIf="!showSpinner" style="overflow-x:scroll;height:600px;">
        <ag-grid-angular style="width: 100%; height: 600px;"
                         id="downlineGrid"
                         class="ag-theme-alpine"
                         [rowData]="downlineAgents"
                         [columnDefs]="columnDefs"
                         [defaultColDef]="defaultColDef"
                         [autoGroupColumnDef]="autoGroupColumnDef"
                         [treeData]="true"
                         [animateRows]="true"
                         [groupDefaultExpanded]="groupDefaultExpanded"
                         [getDataPath]="getDataPath"
                         [excludeChildrenWhenTreeDataFiltering]="true"
                         (gridReady)="onGridReady($event)"
                         (filterChanged)="onFilterChanged($event)"
                         (rowClicked)="onRowClicked($event)">
        </ag-grid-angular>
      </div>
      <div class="row justify-content-center vertical-center" *ngIf="showSpinner">
        <component-loading></component-loading>
      </div>
    </div>
    <div *ngIf="!showDownlineGrid">
      <div style="overflow-x:scroll;height:600px;">
        <ag-grid-angular style="width: 100%; height: 600px;"
                         class="ag-theme-alpine"
                         [rowData]="downlineAgentsByStatus"
                         [columnDefs]="columnDefs1"
                         rowSelection='single'
                         [suppressRowClickSelection]="true"
                         (gridReady)="onGridReady1($event)"
                         (rowClicked)="onRowClicked($event)">
        </ag-grid-angular>
      </div>
    </div>
  </div>
</div>
