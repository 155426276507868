<div *ngIf="(downlineAgents$ | async).length > 0 && profile.status == 'Full'" class="row justify-content-center">
  <div class="col-md-3">
    <div class="form-group">
      <label>Run Report For</label>
      <select id="downlineAgent" class="form-control" [(ngModel)]="downlineAgentId">
        <option value="{{selectedAgentID}}">Myself</option>
        <option *ngFor="let agent of downlineAgents$ | async" value="{{agent.agentId}}">
          {{agent.agentId}} - {{agent.agentName}}
        </option>
      </select>
    </div>
  </div>
</div>
<div class="card card-secondary h-100">
  <div class="card-header">
    <h4 class="card-title w-100">
      <span>Reports</span>
    </h4>
  </div>
  <div class="card-body">
    <div class="row" *ngIf="reports$ | async; else loading;">
      <div *ngFor="let report of reports$ | async" class="col-md-3">
        <div class="info-box grow" style="cursor: pointer;">
          <span class="info-box-icon bg-gradient-light" (click)="getReport(report.reportUrl + '&tguid=')">
            <i class="fad fa-file-chart-line"></i>
          </span>
          <div class="info-box-content" (click)="getReport(report.reportUrl + '&tguid=')">
            <div class="info-box-number" style="white-space: normal !important;">{{report.reportName}}</div>
            <div class="info-box-text text-wrap"><small>{{report.description}}&nbsp;</small></div>
          </div>
        </div>
      </div>
    </div>
    <ng-template #loading>
      <div class="row justify-content-center">
        <component-loading></component-loading>
      </div>
    </ng-template>
  </div>
</div>
