import { group } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { documentGroup } from '../../../models/documentGroup';
import { Profile } from '../../../models/profile';
import { CloudStorageService } from '../../../services/cloud-storage';
import { OnPremStorageService } from '../../../services/onprem-storage';
import { SiteParameterService } from '../../../services/siteparameter.service';

@Component({
    selector: 'app-quicklinks',
    templateUrl: './quicklinks.component.html',
    styleUrls: ['./quicklinks.component.css']
})
export class QuickLinksInfoComponent implements OnInit {
  public groupsLeft!: documentGroup[];
  public groupsRight!: documentGroup[];
  public profile: Profile;
  public accordionColumn: string = "";
  public accordionCollapsed: boolean[] = [];
  private subscriptionName: Subscription; //important to create a subscription

  constructor(private cloudStorageService: CloudStorageService, private siteParmService: SiteParameterService, private onPremService: OnPremStorageService,) {
    this.profile = this.siteParmService.getStaticProfile();
    this.subscriptionName = this.siteParmService.getAgentUpdate().subscribe
      (message => {
        this.profile = siteParmService.getStaticProfile();
        this.getDocuments();
      });
  }

  ngOnInit(): void {
    this.getDocuments();
  }
  getDocuments() {
    let limitedAccessOnly = this.profile.status == 'Limited';
    this.cloudStorageService.getDocuments(this.profile.channelPreferenceValue, limitedAccessOnly)
      .subscribe((groups) => {
        groups.forEach(g => g.documents.forEach(d => {
          if (d.externalLink) {                 
            Object.entries(this.profile).forEach(([key, value]) => {
              const replaceToken = '<' + key + '>';
              if (d.linkUrl.indexOf(replaceToken) > 0) {
                d.linkUrl = d.linkUrl.replace(replaceToken, value);
              }
            });
          }
        }));
        this.groupsLeft = groups.filter(l => l.direction === 'L');
        this.groupsRight = groups.filter(l => l.direction === 'R');
      });
  }
  externalLinkClick(link: string) {
    this.onPremService.getExternalLink(link)
      .pipe(take(1))
      .subscribe(redirect => {
        if (redirect) {          
          const a = document.createElement('a');
          a.setAttribute('style', 'display:none;');
          document.body.appendChild(a);
          a.href = redirect.data.url;
          a.target = '_blank';
          a.click();
          document.body.removeChild(a);
        }
      });
    return link;
  }
  toggleAccordion(side: string, index: number) {
    this.accordionColumn = side;
    this.accordionCollapsed[index] = !this.accordionCollapsed[index];
  }
  getAccordionArrowStyle(side: string, index: number) {
    var style = 'fas fa-angle-up';

    if (side == this.accordionColumn) {
        style = this.accordionCollapsed[index] ? 'fas fa-angle-down' : 'fas fa-angle-up';
    }
    return style;
  }
  ngOnDestroy() { // It's a good practice to unsubscribe to ensure no memory leaks
    this.subscriptionName.unsubscribe();
  }
}
