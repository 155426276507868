import { Component, OnInit } from '@angular/core';
import { SiteParameterService } from '../../../services/siteparameter.service'
import { Observable, Subscription } from 'rxjs';
import { OnPremStorageService } from '../../../services/onprem-storage';
import { Profile } from '../../../models/profile';
import { KPI } from '../../../models/kpi';
import { map, tap } from 'rxjs/operators';
import { APIResponse } from '../../../models/apiresponse';
@Component({
  selector: 'app-kpis',
  templateUrl: './kpis.component.html',
  styleUrls: ['./kpis.component.css']
})
export class KpisComponent implements OnInit {
  public kpis$?: Observable<KPI[]>;
  public kpimessage$?: Observable<any>;
  selectedAgentID: any;
  selectedChannel: number = 0;
  profile!: Profile;
  images: Array<string> = [];
  bgColors: Array<string> = [];
  private agentUpdateSubscription: Subscription; //important to create a subscription
  constructor(private onPremService: OnPremStorageService,
    private siteParmService: SiteParameterService) {
    this.agentUpdateSubscription = this.siteParmService.getAgentUpdate().subscribe
      (message => {
        this.selectedAgentID = message.value;
        this.selectedChannel = this.siteParmService.getStaticProfile().channelPreferenceValue;
        this.getKpis();
      });
  }
  ngOnInit(): void {
    this.profile = this.siteParmService.getStaticProfile();
    this.selectedChannel = this.profile.channelPreferenceValue;
    this.selectedAgentID = this.profile.selectedAgentNumber;
    this.getKpis();
  }
  getKpis(): void {
    let limitedAccessOnly = this.profile.status == 'Limited';
    const response$ = this.onPremService.getKpis(this.selectedAgentID, limitedAccessOnly).pipe(
      map(response => response));

    this.kpis$ = response$.pipe(
      map(kpi => kpi.data.kpis));

    this.kpimessage$ = response$.pipe(
      map(message => message.data.kpimessage));
  }
  public searchKPI(executeSearch: boolean, kpi: string): void {
    if (executeSearch) { this.siteParmService.sendkpiSearchUpdate(kpi); }
  }
  ngOnDestroy() { // It's a good practice to unsubscribe to ensure no memory leaks
    this.agentUpdateSubscription.unsubscribe();
  }
}
